import useLanguage from "../../stores/useLanguage"

export const languageData = {
    Find_Official_Tickets: {
        en: "Find Official \nTickets",
        ge: "მოძებნეთ ოფიციალური \nბილეთები"
    },
    Search_ticket: {
        en: "Search ticket",
        ge: "მოძებნეთ ბილეთი"
    },
    From: {
        en: "From",
        ge: "დან"
    },
    To: {
        en: "To",
        ge: "ში"
    },
    Tbilisi: {
        en: "Tbilisi",
        ge: "თბილისი"
    },
    Batumi: {
        en: "Batumi",
        ge: "ბათუმი"
    },
    Kutaisi: {
        en: "Kutaisi",
        ge: "ქუთაისი"
    },
    Passenger: {
        en: "Passenger",
        ge: "მგზავრი"
    },
    PASSENGER: {
        en: "PASSENGER",
        ge: "მგზავრი"
    },
    CLASS: {
        en: "CLASS",
        ge: "კლასი"
    },
    SECTION: {
        en: "SECTION",
        ge: "განყოფილება"
    },
    Storey_1: {
        en: "1 Storey",
        ge: "1 სართული"
    },
    Passengers: {
        en: "Passengers",
        ge: "მგზავრები"
    },
    Child: {
        en: "Child",
        ge: "ბავშვი"
    },
    ten_years_above: {
        en: "10 years above",
        ge: "10+ წლიდან"
    },
    five_to_ten_years: {
        en: "5-10 years",
        ge: "5-10 წლამდე"
    },
    Search: {
        en: "Search",
        ge: "ძებნა"
    },
    Child_passenger_information: {
        en: "Child passenger information",
        ge: "ინფორმაცია ბავშვ მგზავრზე"
    },
    Departure: {
        en: "Departure",
        ge: "გამგზავრება"
    },
    Return: {
        en: "Return",
        ge: "დაბრუნება"
    },
    Travel_That_Moves_You: {
        en: "Travel That Moves You",
        ge: "მოგზაურობა, რომელიც გიბიძგებს"
    },
    One_app_for_every_step_of_your_journey_travel_planning_has_never_been_easier: {
        en: "One app for every step of your journey—travel planning has never been easier!",
        ge: "აპლიკაცია თქვენი მოგზაურობის ყოველი ნაბიჯისთვის - მოგზაურობის დაგეგმვა არასოდეს ყოფილა ადვილი!"
    },
    Our_Services: {
        en: "Our Services",
        ge: "ჩვენი სერვისები"
    },
    Bus: {
        en: "Bus",
        ge: "ავტობუსი"
    },
    Train: {
        en: "Train",
        ge: "მატარებელი"
    },
    Minibus: {
        en: "Minibus",
        ge: "მიკროავტობუსი"
    },
    Fly: {
        en: "Fly",
        ge: "თვითმფრინავი"
    },
    Car_Rent: {
        en: "Car Rent",
        ge: "მანქანის გაქირავება"
    },
    Terms_and_Conditions: {
        en: "Terms & Conditions",
        ge: "წესები და პირობები"
    },
    Privacy: {
        en: "Privacy",
        ge: "კონფიდენციალურობა"
    },
    About_Us: {
        en: "About Us",
        ge: "ჩვენს შესახებ"
    },
    Mody_All_rights_reserved: {
        en: "© 2024 Mody. All rights reserved.",
        ge: "© 2024 Mody. ყველა უფლება დაცულია."
    },
    Currency: {
        en: "Currency",
        ge: "ვალუტა"
    },
    Language: {
        en: "Language",
        ge: "ენა"
    },
    USD: {
        en: "USD",
        ge: "USD"
    },
    GEL: {
        en: "GEL",
        ge: "ლარი"
    },
    ENG: {
        en: "ENG",
        ge: "ინგ"
    },
    GEO: {
        en: "GEO",
        ge: "ქარ"
    },
    Log_In: {
        en: "Log In",
        ge: "შესვლა"
    },
    Whats_your_number: {
        en: "What’s your number?",
        ge: "შეიყვანეთ თქვენი ნომერი"
    },
    If_you_dont_have_an_account_use_phone_registration_we_will_text_you_to_verify_your_phone_number: {
        en: "If you don’t have an account use phone registration. we will text you to verify your phone number",
        ge: "თუ არ გაქვთ ანგარიში, გამოიყენეთ ტელეფონის რეგისტრაცია. ჩვენ გამოგიგზავნით შეტყობინებას თქვენი ტელეფონის ნომრის დასადასტურებლად"
    },
    Phone_number: {
        en: "Phone number",
        ge: "ტელეფონის ნომერი"
    },
    Next: {
        en: "Next",
        ge: "შემდეგი"
    },
    Dont_have_an_account: {
        en: "Don’t have an account?",
        ge: "არ გაქვთ ანგარიში?"
    },
    Sign_Up: {
        en: "Sign Up",
        ge: "რეგისტრაცია"
    },
    Fill_in_the_fields: {
        en: "Fill in the fields",
        ge: "შეავსეთ ველები"
    },
    Enter_the_6_digit_code_sent_to_you_at: {
        en: "Enter the 6-digit code sent to you at",
        ge: "შეიყვანეთ თქვენთვის გამოგზავნილი 6-ნიშნა კოდი"
    },
    Resend_code: {
        en: "Resend code",
        ge: "კოდის თავიდან გაგზავნა"
    },
    Go_Back: {
        en: "Go Back",
        ge: "უკან დაბრუნება"
    },
    Something_went_wrong_please_try_again_or_contact_us: {
        en: "Something went wrong please try again or contact us",
        ge: "რაღაც შეფერხდა, გთხოვთ, სცადოთ ხელახლა ან დაგვიკავშირდით"
    },
    Account_Settings: {
        en: "Account Settings",
        ge: "ანგარიშის პარამეტრები"
    },
    Payment: {
        en: "Payment",
        ge: "გადახდა"
    },
    Redeem_codes: {
        en: "Redeem codes",
        ge: "კოდების განაღდება"
    },
    Invite_friends: {
        en: "Invite friends",
        ge: "მეგობრების მოწვევა"
    },
    Log_out: {
        en: "Log out",
        ge: "გამოსვლა"
    },
    Loading: {
        en: "Loading",
        ge: "იტვირთება"
    },
    Successfully_logged_out: {
        en: "Successfully logged out!",
        ge: "წარმატებით გამოხვედით!"
    },
    My_tickets: {
        en: "My tickets",
        ge: "ჩემი ბილეთები"
    },
    Already_have_an_account: {
        en: "Already have an account?",
        ge: "გაქვთ ანგარიში?"
    },
    First_name: {
        en: "First name",
        ge: "სახელი"
    },
    Last_name: {
        en: "Last name",
        ge: "გვარი"
    },
    ID_Number: {
        en: "ID Number",
        ge: "პირადი ნომერი"
    },
    Email: {
        en: "Email",
        ge: "მეილი"
    },
    Wrong_or_expired_code: {
        en: "Wrong or expired code",
        ge: "კოდი არასწორია ან ვადა გაუვიდა"
    },
    Payment_methods: {
        en: "Payment methods",
        ge: "გადახდის მეთოდები"
    },
    Update: {
        en: "Update",
        ge: "განახლება"
    },
    A_verification_code_will_be_sent_to_this_number: {
        en: "A verification code will be sent to this number",
        ge: "ამ ნომერზე გაიგზავნება დამადასტურებელი კოდი"
    },
    Cancel: {
        en: "Cancel",
        ge: "გაუქმება"
    },
    Save: {
        en: "Save",
        ge: "შენახვა"
    },
    Soon: {
        en: "Soon",
        ge: "მალე"
    },
    Adult: {
        en: "Adult",
        ge: "სწულწლოვანი"
    },
    Search_buses_Tickets: {
        en: "Search buses Tickets",
        ge: "მოძებნე ავტობუსის ბილეთები"
    },
    Select_Outbound: {
        en: "Select Outbound",
        ge: "აირჩიეთ გამავალი დრო"
    },
    Select_Return: {
        en: "Select Return",
        ge: "აირჩიეთ დაბრუნების დრო"
    },
    CHEAPEST: {
        en: "CHEAPEST",
        ge: "იაფი"
    },
    One_way: {
        en: "One-way",
        ge: "ერთი გზა"
    },
    Two_way: {
        en: "Two-way",
        ge: "ორი გზა"
    },
    Tickets_not_found: {
        en: "Tickets not found",
        ge: "ბილეთები ვერ მოიძებნა"
    },
    Kutaisi_airport: {
        en: "Kutaisi airport",
        ge: "ქუთაისის აეროპორტი"
    },
    Tbilisi_Station_square_1: {
        en: "Tbilisi Liberty square",
        ge: "თბილისი თავისუფლების მოედანი"
    },
    Batumi_Station_2: {
        en: "Batumi, Gogebashbili street 2",
        ge: "ბათუმი, გოგებაშვილის ქუჩა 2"
    },
    Details: {
        en: "Details",
        ge: "დეტალები"
    },
    Continue: {
        en: "Continue",
        ge: "გაგრძელება"
    },
    Choose_tickets: {
        en: "Choose tickets",
        ge: "აირჩიეთ ბილეთები"
    },
    Passenger_details: {
        en: "Passenger details",
        ge: "მგზავრის დეტალები"
    },
    Review_and_pay: {
        en: "Review and pay",
        ge: "გადახედვა და გადახდა"
    },
    You_must_present_valid_identification_national_ID_card_passport_or_BahnCard__during_the_ticket_inspection: {
        en: "You must present valid identification (national ID card, passport or BahnCard) during the ticket inspection.",
        ge: "ბილეთის შემოწმებისას უნდა წარმოადგინოთ მოქმედი პირადობის მოწმობა (სახელმწიფო პირადობის მოწმობა, პასპორტი ან BahnCard)."
    },
    Main_Passanger: {
        en: "Main Passanger",
        ge: "მთავარი მგზავრი"
    },
    Required: {
        en: "Required",
        ge: "სავალდებულო"
    },
    Optional: {
        en: "Optional",
        ge: "არასავალდებულო"
    },
    Save_these_details_to_your_profile: {
        en: "Save these details to your profile",
        ge: "შეინახეთ ეს დეტალები თქვენს პროფილში"
    },
    Fill_main_passenger_information: {
        en: "Fill main passenger information",
        ge: "შეავსეთ მთავარი მგზავრის ინფორმაცია"
    },
    Review: {
        en: "Review",
        ge: "მიმოხილვა"
    },
    Bus_ticket: {
        en: "Bus ticket",
        ge: "ავტობუსის ბილეთი"
    },
    Contact_info: {
        en: "Contact info",
        ge: "საკონტაქტო ინფორმაცია"
    },
    Where_do_you_want_get_tickets: {
        en: "Where do you want get tickets?",
        ge: "სად გინდა ბილეთების მიღება?"
    },
    Pay_with_new_card: {
        en: "Pay with new card",
        ge: "გადაიხადე ახალი ბარათით"
    },
    Pay_with_cash: {
        en: "Pay with cash",
        ge: "გადაიხადე ქეშით"
    },
    Use_a_Discount: {
        en: "Use a Discount",
        ge: "გამოიყენე ფასდაკლება"
    },
    Apply: {
        en: "Apply",
        ge: "გამოყენება"
    },
    Code: {
        en: "Code",
        ge: "კოდი"
    },
    Tickets: {
        en: "Tickets",
        ge: "ბილეთები"
    },
    Make_sure_that_upper_and_lower_case_characters_are_correct: {
        en: "Make sure that upper and lower case characters are correct",
        ge: "დარწმუნდით, რომ დიდი და პატარა ასოები სწორია"
    },
    Service_fee: {
        en: "Service fee",
        ge: "მომსახურების საფასური"
    },
    Discount: {
        en: "Discount",
        ge: "ფასდაკლება"
    },
    Total: {
        en: "Total",
        ge: "სულ"
    },
    Pay_now: {
        en: "Pay now",
        ge: "გადახდა"
    },
    Choose_payment_method: {
        en: "Choose payment method",
        ge: "აირჩიეთ გადახდის მეთოდი"
    },
    Total_balance: {
        en: "Total balance",
        ge: "ბალანსი"
    },
    Add_payment_method: {
        en: "Add payment method",
        ge: "დაამატეთ გადახდის მეთოდი"
    },
    Successful: {
        en: "Successful",
        ge: "წარმატებული"
    },
    Pending: {
        en: "Pending",
        ge: "მუშავდება"
    },
    Failed: {
        en: "Failed",
        ge: "ვერ მოხერხდა"
    },
    OUTBOUND: {
        en: "OUTBOUND",
        ge: "გამავალი"
    },
    RETURN: {
        en: "RETURN",
        ge: "დაბრუნების"
    },
    Saved_Passengers: {
        en: "Saved Passengers",
        ge: "დამახსოვრებული მგზავრები"
    },
    Back_to_my_tickets: {
        en: "Back to my tickets",
        ge: "ბილეთებზე დაბრუნება"
    },
    Book_your_trip: {
        en: "Book your trip",
        ge: "დაჯავშნეთ თქვენი ბილეთი"
    },
    No_bookings_yet: {
        en: "No bookings yet",
        ge: "ჯავშნები არ არის"
    },
    You_have_successfully_purchased_tickets: {
        en: "You have successfully \npurchased tickets",
        ge: "თქვენ წარმატებით შეიძინეთ \nბილეთები"
    },
    Print: {
        en: "Print",
        ge: "ბეჭდვა"
    },
    Share: {
        en: "Share",
        ge: "გაზიარება"
    },
    Review_Journey_Details: {
        en: "Review Journey Details",
        ge: "გადახედეთ მოგზაურობის დეტალებს"
    },
    Popular_Destinations: {
        en: "Popular Destinations",
        ge: "პოპულარული ადგილები"
    },
    Download_Our_free_app: {
        en: "Download Our free app",
        ge: "ჩამოტვირთეთ ჩვენი უფასო აპლიკაცია"
    },
    UPCOMING: {
        en: "UPCOMING",
        ge: "მომავალი"
    },
    PAST: {
        en: "PAST",
        ge: "გასული"
    },
    invalid_user_id: {
        en: "Incorrect user id",
        ge: "არასწორი პირადი ნომერი"
    },
    no_number_allowed_in_name: {
        en: "No number allowed in name",
        ge: "რიცხვი არ შეიძლება სახელში"
    },
    no_number_allowed_in_surname: {
        en: "No number allowed in last name",
        ge: "რიცხვი არ შეიძლება გვარში"
    },
    Edit_Phone: {
        en: "Edit Phone",
        ge: "ნომრის შეცვლა"
    },
    "code-not-found-or-expired": {
        en: "Code not found or expired",
        ge: "კოდი ვერ მოიძებნა ან ვადაგასულია"
    },
    Just_enter_your_Redeem_code_below: {
        en: "Just enter your Redeem code below",
        ge: "უბრალოდ შეიყვანეთ თქვენი კოდი ქვემოთ"
    },
    you_successfully_redeemed_code: {
        en: "You successfully redeemed code",
        ge: "თქვენ წარმატებით გამოიყენეთ კოდი"
    },
    Know_someone_who_needs_a_ride_Invite_them_to_mody_to_get_give_free_credits: {
        en: "Know someone who needs a ride? Invite them to mody to get & give free credits!",
        ge: "იცნობთ ვინმეს, ვისაც მგზავრობა სჭირდება? მოიწვიე ისინი მოდიში, რომ მიიღონ და მიიღე უფასო კრედიტები!"
    },
    Invite_your_friends_to_ride_with_Mody_so_they_can_get_going_now: {
        en: "Invite your friends to ride with Mody so they can get going now!",
        ge: "მოიწვიე შენი მეგობრები მოდიში რათა ახლავე წავიდეთ!"
    },
    Invite: {
        en: "Invite",
        ge: "მოწვევა"
    },
    Send_copied_text_to_your_friend: {
        en: "Send copied text to your friend",
        ge: "გაუგზავნეთ კოპირებული ტექსტი თქვენს მეგობარს"
    },
    Code_successfully_copied: {
        en: "Code successfully copied",
        ge: "კოდი წარმატებით დაკოპირდა"
    },
    Click_to_get_the_invite_code: {
        en: "Click to get the invite code",
        ge: "დააჭირეთ მოწვევის კოდის მისაღებად"
    },
    "Not enough money on balance": {
        en: "Not enough money on balance",
        ge: "ბალანსზე საკმარისი თანხა არ არის"
    },
}

export const getLanguageItem = (item: keyof typeof languageData) => {
    const language = useLanguage.getState()
    return languageData[item]?.[language.language] || ""
}