const RedeemCodeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={161}
    height={175}
    fill="none"
  >
    <g filter="url(#a)">
      <path
        fill="url(#b)"
        d="m137.807 54.566-6.084 21.293-1.738 5.939-15.209 53.449c-1.159 4.346-5.794 6.808-10.14 5.649l-25.783-7.387-26.507-7.677c-4.346-1.159-6.808-5.794-5.65-10.139l19.99-70.252 3.042-10.43c1.014-3.476 3.476-6.373 6.663-7.966l29.694-14.195c4.49-2.173 9.85-.58 12.602 3.621l17.671 27.666c1.883 3.042 2.463 6.808 1.449 10.43Z"
      />
    </g>
    <g filter="url(#c)">
      <path
        fill="url(#d)"
        d="M62.775 129.887 24.39 91.502c-3.187-3.186-3.187-8.4 0-11.587l59.388-59.389c2.607-2.607 6.084-3.91 9.705-3.766l32.736 2.028c4.925.29 8.98 4.2 9.27 9.27l2.028 32.736c.29 3.622-1.159 7.243-3.766 9.705l-59.388 59.388c-3.187 3.187-8.401 3.187-11.588 0Z"
      />
    </g>
    <path
      fill="#D1D5DB"
      d="M122.24 46.05c3.846-3.846 3.846-10.083 0-13.93-3.847-3.846-10.083-3.846-13.93 0-3.846 3.847-3.846 10.084 0 13.93 3.847 3.847 10.083 3.847 13.93 0ZM43.51 87.591c-.724 0-1.448-.29-2.028-.869-1.158-1.158-1.158-2.897 0-4.055l37.661-37.661c1.159-1.159 2.897-1.159 4.056 0 1.159 1.159 1.159 2.897 0 4.056l-37.516 37.66c-.58.58-1.304.87-2.173.87ZM53.649 97.586c-.724 0-1.449-.29-2.028-.869-1.159-1.159-1.159-2.897 0-4.056l37.516-37.516c1.159-1.158 2.897-1.158 4.056 0 1.159 1.16 1.159 2.897 0 4.056L55.677 96.717c-.58.58-1.304.87-2.028.87ZM67.7 111.637c-.725 0-1.449-.29-2.028-.869-1.159-1.159-1.159-2.897 0-4.056l12.457-12.457c1.159-1.159 2.897-1.159 4.056 0 1.158 1.159 1.158 2.897 0 4.056l-12.457 12.457c-.58.579-1.304.869-2.028.869Z"
    />
    <path
      fill="#6B7280"
      d="M149.54 24.293c0 8.401-4.925 16.078-12.747 19.41l-.579-1.449v-1.593c6.228-3.042 10.284-9.416 10.284-16.368 0-9.995-8.112-18.251-18.251-18.251-9.995 0-18.251 8.111-18.251 18.25 0 2.029.29 3.912 1.014 5.795 2.028 5.939 6.953 10.574 13.181 12.022l-.29 1.449-.869 1.448c-6.808-1.738-12.167-6.663-14.629-13.036-.87-2.318-1.449-4.925-1.449-7.532C107.099 12.56 116.514 3 128.247 3c11.877 0 21.293 9.56 21.293 21.293Z"
    />
    <defs>
      <linearGradient
        id="b"
        x1={92.326}
        x2={92.326}
        y1={8.889}
        y2={142.576}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={79.739}
        x2={79.739}
        y1={14.077}
        y2={133.523}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDFEFF" />
        <stop offset={0.996} stopColor="#ECF0F5" />
      </linearGradient>
      <filter
        id="a"
        width={135.89}
        height={173.302}
        x={24.411}
        y={0.88}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_926_8480"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_926_8480"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        width={159.554}
        height={159.528}
        x={0}
        y={5.75}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={11} />
        <feGaussianBlur stdDeviation={11} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_926_8480"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_926_8480"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default RedeemCodeSvg
