import * as React from "react"
const RouteIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
    >
        <g clipPath="url(#a)">
            <path
                stroke="#111928"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.3}
                d="M7.666 3.333h.29c2.031 0 3.047 0 3.433.365.333.316.48.78.39 1.23-.103.52-.933 1.107-2.591 2.28l-2.71 1.917c-1.658 1.173-2.488 1.76-2.592 2.28-.09.45.058.915.391 1.23C4.663 13 5.68 13 7.71 13h.623m-3-9.667a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm9.333 9.334a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default RouteIcon
