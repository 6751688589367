const PlusCircle = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            fill="#7D57FD"
            d="M11 1.833C5.95 1.833 1.834 5.95 1.834 11c0 5.05 4.115 9.167 9.166 9.167S20.167 16.05 20.167 11c0-5.05-4.116-9.167-9.167-9.167Zm3.667 9.854h-2.98v2.98a.693.693 0 0 1-.687.687.692.692 0 0 1-.687-.687v-2.98h-2.98A.693.693 0 0 1 6.646 11c0-.376.312-.688.688-.688h2.979V7.333c0-.376.311-.687.687-.687.376 0 .688.311.688.687v2.98h2.979c.376 0 .687.311.687.687a.692.692 0 0 1-.687.688Z"
        />
    </svg>
)
export default PlusCircle