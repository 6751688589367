const DollarIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M7.792 13.444c0 1.182.957 2.14 2.138 2.14h1.987a2.292 2.292 0 0 0 0-4.584h-1.834a2.292 2.292 0 1 1 0-4.583h1.986c1.182 0 2.14.957 2.14 2.139m-3.21-3.514v1.375m0 9.166v1.375M20.168 11a9.167 9.167 0 1 1-18.334 0 9.167 9.167 0 0 1 18.334 0Z"
        />
    </svg>
)
export default DollarIcon