import * as React from "react"
const CameraIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        {...props}
    >
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M1.333 5.585c0-.234 0-.35.01-.449a2 2 0 0 1 1.793-1.793c.099-.01.222-.01.468-.01.095 0 .142 0 .182-.002a1.333 1.333 0 0 0 1.157-.833c.015-.038.029-.08.057-.165.028-.084.042-.126.057-.163a1.333 1.333 0 0 1 1.157-.834c.04-.003.085-.003.173-.003h3.226c.089 0 .133 0 .173.003.514.031.965.356 1.157.834.015.037.029.08.057.163.028.085.042.127.057.164.192.478.643.803 1.157.834.04.002.088.002.182.002.246 0 .37 0 .468.01a2 2 0 0 1 1.793 1.793c.01.099.01.215.01.449V10.8c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H4.533c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874c-.218-.428-.218-.988-.218-2.108V5.585Z"
        />
        <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M8 11a2.667 2.667 0 1 0 0-5.333A2.667 2.667 0 0 0 8 11Z"
        />
    </svg>
)
export default CameraIcon
