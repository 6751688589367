import * as React from "react"
const MasterCardIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={13}
        fill="none"
    >
        <path
            fill="#ED0006"
            fillRule="evenodd"
            d="M10.663 11.427a6.182 6.182 0 0 1-4.01 1.467c-3.414 0-6.182-2.75-6.182-6.141C.47 3.36 3.239.61 6.653.61c1.53 0 2.93.552 4.01 1.467a6.182 6.182 0 0 1 4.01-1.467c3.415 0 6.183 2.75 6.183 6.142 0 3.392-2.768 6.141-6.183 6.141-1.53 0-2.93-.552-4.01-1.467Z"
            clipRule="evenodd"
        />
        <path
            fill="#F9A000"
            fillRule="evenodd"
            d="M10.663 11.427a6.112 6.112 0 0 0 2.172-4.674 6.112 6.112 0 0 0-2.172-4.675 6.182 6.182 0 0 1 4.01-1.467c3.415 0 6.183 2.75 6.183 6.142 0 3.392-2.768 6.141-6.183 6.141-1.53 0-2.93-.552-4.01-1.467Z"
            clipRule="evenodd"
        />
        <path
            fill="#FF5E00"
            fillRule="evenodd"
            d="M10.663 11.427a6.112 6.112 0 0 0 2.172-4.674 6.112 6.112 0 0 0-2.172-4.675 6.112 6.112 0 0 0-2.172 4.675c0 1.871.843 3.548 2.172 4.674Z"
            clipRule="evenodd"
        />
    </svg>
)
export default MasterCardIcon
