import * as React from "react"
const UserXsIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.3}
            d="M13.334 14c0-.93 0-1.396-.115-1.774a2.666 2.666 0 0 0-1.778-1.778c-.378-.115-.844-.115-1.774-.115H6.334c-.93 0-1.396 0-1.774.115a2.666 2.666 0 0 0-1.778 1.778c-.115.378-.115.844-.115 1.774M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
        />
    </svg>
)
export default UserXsIcon
