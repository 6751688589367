import * as React from "react"
const UserPlusIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M14.25 7.5V3M12 5.25h4.5M12 15.75v-.9c0-1.26 0-1.89-.245-2.371a2.252 2.252 0 0 0-.984-.984c-.48-.245-1.11-.245-2.371-.245H5.1c-1.26 0-1.89 0-2.371.245a2.25 2.25 0 0 0-.984.984c-.245.48-.245 1.11-.245 2.371v.9M9.375 5.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
        />
    </svg>
)
export default UserPlusIcon
