import * as React from "react"
const GeorgianFlagIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#F3F4F6"
                d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11Z"
            />
            <path
                fill="#D80027"
                d="M21.907 9.565h-9.472V.093a11.11 11.11 0 0 0-2.87 0v9.472H.093a11.11 11.11 0 0 0 0 2.87h9.472v9.472a11.118 11.118 0 0 0 2.87 0v-9.472h9.472a11.116 11.116 0 0 0 0-2.87Z"
            />
            <path
                fill="#D80027"
                d="M6.696 5.262V3.827H5.26v1.435H3.826v1.435h1.435v1.435h1.435V6.697H8.13V5.262H6.696ZM16.739 5.262V3.827h-1.435v1.435H13.87v1.435h1.434v1.435h1.435V6.697h1.435V5.262h-1.435ZM6.696 15.305V13.87H5.26v1.435H3.826v1.435h1.435v1.434h1.435V16.74H8.13v-1.435H6.696ZM16.739 15.305V13.87h-1.435v1.435H13.87v1.435h1.434v1.434h1.435V16.74h1.435v-1.435h-1.435Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h22v22H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default GeorgianFlagIcon
