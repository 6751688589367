import * as React from "react"
const TriplePeopleMiddle = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={141}
    height={141}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#fff"
        d="M112.365 94.388c.326 2.412-1.567 4.635-4.047 4.635H31.82c-2.48 0-4.372-2.223-4.047-4.635.718-5.024 1.827-6.067 9.268-9.069l1.828-.728c3.46-1.37 7.964-3.065 14.033-5.603 1.372-.59 2.481-1.633 3.069-3.014a5.908 5.908 0 0 0 .521-2.412V62.144c-.196-.264-.39-.465-.521-.716a10.927 10.927 0 0 1-1.828-4.962l-.98-.464c-2.936.728-2.675-2.412-3.459-8.416-.326-2.475.066-3.002 1.175-3.392l.914-1.243c-5.613-12.725-2.806-20.688 2.741-23.502-1.697-4.045-1.828-5.476-1.828-5.476s11.815 1.96 15.862 1.168c5.157-1.043 13.12.2 16.057 7.06 4.895 1.959 6.788 5.023 7.244 8.415.719 5.351-2.349 11.23-2.937 13.516.066.125.066.188.13.25.785.403 1.045 1.056.785 3.204-.719 5.678-.523 9.207-3.46 8.416l-2.611 4.572c-.13.527-.13.716-.196 1.105-.13.854-.065 2.55-.065 11.883 0 1.118.326 2.223.914 3.065.652.98 1.567 1.834 2.676 2.286.065 0 .065 0 .13.075 6.723 2.802 11.554 4.623 15.079 6.005 8.027 3.127 9.267 4.107 10.051 9.395Z"
      />
    </g>
    <path
      fill="#9AA2B2"
      d="M88.999 44.132c.26-1.445-.392-3.203-.914-4.447-.065-.188-.196-.39-.261-.653-2.285-4.56-7.31-6.394-12.14-6.658-12.336-.653-13.447 1.696-17.298-1.82 1.176 1.82 1.306 4.634-.521 8.151-1.307 2.487-3.786 3.593-5.94 4.309-5.614-12.725-2.806-20.688 2.74-23.49-1.827-4.12-1.956-5.551-1.956-5.551s11.813 1.96 15.86 1.168c5.157-1.043 13.119.2 16.057 7.06 4.895 1.959 6.788 5.023 7.245 8.415.783 5.288-2.285 11.167-2.872 13.516Z"
    />
    <path
      fill="#9AA2B2"
      d="M105.446 98.96c3.003-4.572 1.828-8.88-3.002-13.968 7.897 3.128 9.203 4.108 9.921 9.333.327 2.412-1.566 4.635-4.047 4.635h-2.872Z"
      opacity={0.1}
    />
    <defs>
      <linearGradient
        id="b"
        x1={48.841}
        x2={92.006}
        y1={29.058}
        y2={29.058}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B1B7C0" />
        <stop offset={1} stopColor="#8B909A" />
      </linearGradient>
      <filter
        id="a"
        width={139.933}
        height={140.319}
        x={0.102}
        y={0.156}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={13.817} />
        <feGaussianBlur stdDeviation={13.817} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_799_1249"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_799_1249"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default TriplePeopleMiddle
