const ChevronDown = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <path
            stroke="#6B7280"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m4.5 6.75 4.5 4.5 4.5-4.5"
        />
    </svg>
)
export default ChevronDown
