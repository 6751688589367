const MinusCircle = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            fill="#9CA3AF"
            d="M11 1.833C5.95 1.833 1.834 5.95 1.834 11c0 5.05 4.115 9.167 9.166 9.167S20.167 16.05 20.167 11c0-5.05-4.116-9.167-9.167-9.167Zm3.594 9.854H7.26A.693.693 0 0 1 6.573 11c0-.376.311-.688.687-.688h7.334a.687.687 0 1 1 0 1.375Z"
        />
    </svg>
)
export default MinusCircle