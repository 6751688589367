import batumiImg from "./popular-destinations/batumi.png"
import tbilisiImg from "./popular-destinations/tbilisi.png"
import kutaisiImg from "./popular-destinations/kutaisi.jpg"

import searchTicketsMobileImg from "./search-tickets-mobile.png"
import iphoneLayoutImg from "./iphone-layout.png"

export const batumiScr = batumiImg;
export const tbilisiScr = tbilisiImg;
export const kutaisiScr = kutaisiImg;

export const searchTicketsMobileScr = searchTicketsMobileImg;
export const iphoneLayoutScr = iphoneLayoutImg;