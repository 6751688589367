const UserIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.4}
            d="M18.333 19.25c0-1.28 0-1.919-.158-2.44a3.667 3.667 0 0 0-2.444-2.444c-.52-.158-1.16-.158-2.44-.158H8.708c-1.28 0-1.919 0-2.44.158a3.667 3.667 0 0 0-2.444 2.445c-.157.52-.157 1.16-.157 2.439M15.125 6.875a4.125 4.125 0 1 1-8.25 0 4.125 4.125 0 0 1 8.25 0Z"
        />
    </svg>
)
export default UserIcon