import * as React from "react"

const ReturnSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={76}
        height={18}
        fill="none"
    >
        <path
            fill="#E3A008"
            d="M23.933 14V4.545h3.545c.726 0 1.336.127 1.828.379.496.252.87.606 1.122 1.062.255.452.383.98.383 1.583 0 .607-.13 1.133-.388 1.58a2.56 2.56 0 0 1-1.13 1.029c-.5.24-1.112.36-1.838.36H24.93V9.116h2.294c.425 0 .773-.059 1.043-.176.271-.12.471-.294.6-.521.133-.231.2-.514.2-.85 0-.335-.067-.621-.2-.858a1.263 1.263 0 0 0-.604-.545c-.271-.126-.62-.19-1.048-.19h-1.57V14h-1.712Zm4.884-4.284L31.157 14h-1.91l-2.3-4.284h1.87ZM32.387 14V4.545h6.15v1.436H34.1v2.567h4.118v1.436H34.1v2.58h4.474V14h-6.186Zm7.482-8.019V4.545h7.543v1.436H44.49V14h-1.7V5.981H39.87Zm14.957-1.436h1.713v6.177c0 .677-.16 1.273-.48 1.787a3.245 3.245 0 0 1-1.34 1.205c-.575.286-1.247.43-2.017.43-.772 0-1.446-.144-2.022-.43a3.245 3.245 0 0 1-1.338-1.205c-.317-.514-.476-1.11-.476-1.787V4.545h1.713v6.034c0 .394.086.745.258 1.053.176.308.422.55.739.725.317.172.693.258 1.126.258.434 0 .81-.086 1.127-.258a1.84 1.84 0 0 0 .739-.725c.172-.308.258-.659.258-1.053V4.545ZM58.4 14V4.545h3.546c.726 0 1.336.127 1.828.379.495.252.87.606 1.122 1.062.255.452.383.98.383 1.583 0 .607-.13 1.133-.388 1.58a2.56 2.56 0 0 1-1.131 1.029c-.498.24-1.111.36-1.837.36h-2.526V9.116h2.295c.425 0 .772-.059 1.043-.176.271-.12.471-.294.6-.521.133-.231.199-.514.199-.85 0-.335-.066-.621-.199-.858a1.263 1.263 0 0 0-.604-.545c-.271-.126-.62-.19-1.048-.19h-1.57V14H58.4Zm4.885-4.284L65.624 14h-1.911l-2.3-4.284h1.87Zm11.34-5.17V14h-1.523l-4.455-6.44h-.079V14h-1.713V4.545h1.533l4.45 6.445h.083V4.545h1.704Z"
        />
        <g clipPath="url(#a)">
            <path
                stroke="#E3A008"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="m5.625 9 2.25 2.25 4.5-4.5M16.5 9a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default ReturnSvg
