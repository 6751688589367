const EnglishFlagIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#F0F0F0"
                d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Z"
            />
            <path
                fill="#0052B4"
                d="M2.067 3.91A9.963 9.963 0 0 0 .344 7.39h5.203l-3.48-3.48ZM19.656 7.39a9.964 9.964 0 0 0-1.723-3.479l-3.48 3.48h5.203ZM.344 12.608a9.964 9.964 0 0 0 1.723 3.48l3.48-3.48H.344ZM16.088 2.066a9.964 9.964 0 0 0-3.48-1.722v5.202l3.48-3.48ZM3.912 17.931a9.966 9.966 0 0 0 3.48 1.723v-5.202l-3.48 3.48ZM7.391.344a9.965 9.965 0 0 0-3.48 1.722l3.48 3.48V.344ZM12.608 19.654a9.968 9.968 0 0 0 3.48-1.723l-3.48-3.479v5.202ZM14.454 12.608l3.48 3.48a9.963 9.963 0 0 0 1.722-3.48h-5.202Z"
            />
            <path
                fill="#D80027"
                d="M19.915 8.696h-8.61V.085a10.1 10.1 0 0 0-2.61 0v8.61H.086a10.1 10.1 0 0 0 0 2.61h8.61v8.61a10.114 10.114 0 0 0 2.61 0v-8.61h8.61a10.115 10.115 0 0 0 0-2.61Z"
            />
            <path
                fill="#D80027"
                d="m12.609 12.61 4.462 4.462c.205-.205.401-.42.588-.642l-3.82-3.82h-1.23ZM7.391 12.61 2.93 17.071c.205.205.42.4.642.588l3.82-3.821v-1.23ZM7.391 7.39 2.93 2.929c-.205.206-.401.42-.588.642l3.82 3.82h1.23ZM12.609 7.392l4.462-4.463a9.982 9.982 0 0 0-.642-.587l-3.82 3.82v1.23Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default EnglishFlagIcon