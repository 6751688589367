const ModyLogoPurple = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={48}
        height={48}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#7D57FD"
                d="M37.224 48H10.776A10.776 10.776 0 0 1 0 37.224V10.776A10.776 10.776 0 0 1 10.775 0h26.45A10.775 10.775 0 0 1 48 10.775v26.45A10.775 10.775 0 0 1 37.224 48ZM6.983 28.424a1.475 1.475 0 0 0 1.279-.739l2.645-4.576a.047.047 0 0 1 .043-.025.05.05 0 0 1 .045.025l1.726 2.985a.049.049 0 0 0 .043.026.051.051 0 0 0 .045-.026l1.726-2.985a.052.052 0 0 1 .045-.026.053.053 0 0 1 .045.026l1.764 3.064a4.61 4.61 0 0 0 4.226 2.496c3.1 0 4.653-2.496 4.653-4.669 0-2.173-1.52-4.667-4.654-4.667a4.898 4.898 0 0 0-3.25 1.19.054.054 0 0 0-.012.066l.196.349a1.385 1.385 0 0 0 1.828.611c.381-.172.796-.256 1.213-.247 1.812 0 2.69 1.442 2.69 2.698s-.897 2.698-2.69 2.698a2.614 2.614 0 0 1-2.367-1.3l-3.093-5.361a.964.964 0 0 0-.825-.46.055.055 0 0 0-.045.025l-1.505 2.607-1.506-2.607a.05.05 0 0 0-.045-.026.964.964 0 0 0-.825.46L5.58 28.348a.053.053 0 0 0 0 .051.049.049 0 0 0 .045.026h1.358Zm23.136-8.85h-3.886a.053.053 0 0 0-.052.053v8.744a.052.052 0 0 0 .053.053h1.878a.053.053 0 0 0 .038-.016.057.057 0 0 0 .013-.037v-6.96a.045.045 0 0 1 .016-.034.05.05 0 0 1 .037-.016h1.372c1.353 0 2.915.784 2.915 2.639 0 1.75-1.305 2.378-2.06 2.6-.475.139-.928.729-.928 1.454v.284c0 .013.005.026.014.037a.053.053 0 0 0 .037.014h.545a4.42 4.42 0 0 0 4.449-4.35c.014-2.66-2.051-4.465-4.442-4.465Zm9.57.747-1.49 2.507a.055.055 0 0 1-.046.026.057.057 0 0 1-.045-.026l-1.489-2.503a1.458 1.458 0 0 0-1.313-.745h-1.371a.051.051 0 0 0-.051.053c0 .009.003.018.008.026l3.262 5.152v3.564a.053.053 0 0 0 .053.053h1.879a.05.05 0 0 0 .037-.018.047.047 0 0 0 .014-.035v-3.568l3.264-5.152a.058.058 0 0 0 0-.053.05.05 0 0 0-.045-.026h-1.372a1.449 1.449 0 0 0-1.295.745Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h48v48H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default ModyLogoPurple
