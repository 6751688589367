import * as React from "react"
const ReceiptIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        fill="none"
    >
        <path
            fill="#7D57FD"
            d="M8.412 24.625c1.025-1.1 2.588-1.012 3.488.188l1.262 1.687c1.013 1.337 2.65 1.337 3.663 0l1.262-1.688c.9-1.2 2.463-1.287 3.488-.187 2.225 2.375 4.038 1.587 4.038-1.738V8.8c.012-5.038-1.163-6.3-5.888-6.3h-9.45c-4.725 0-5.9 1.263-5.9 6.3v14.075c0 3.337 1.825 4.113 4.037 1.75Z"
            opacity={0.4}
        />
        <path
            fill="#7D57FD"
            d="M20.288 14.688h-6.875a.944.944 0 0 1-.938-.938c0-.512.425-.938.938-.938h6.875c.512 0 .937.426.937.938a.944.944 0 0 1-.937.938ZM20.288 9.688h-6.875a.944.944 0 0 1-.938-.938c0-.512.425-.938.938-.938h6.875c.512 0 .937.426.937.938a.944.944 0 0 1-.937.938ZM9.725 10c-.687 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25 0 .688-.562 1.25-1.25 1.25ZM9.725 15c-.687 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25 0 .688-.562 1.25-1.25 1.25Z"
        />
    </svg>
)
export default ReceiptIcon
