import * as React from "react"
const UserWithBg = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={80}
        fill="none"
        {...props}
    >
        <circle cx={40} cy={40} r={40} fill="#F3F4F6" />
        <g fill="#111928" opacity={0.3}>
            <path d="M40 25a7.131 7.131 0 0 0-7.125 7.125c0 3.855 3.015 6.975 6.945 7.11.12-.015.24-.015.33 0h.105a7.107 7.107 0 0 0 6.87-7.11A7.131 7.131 0 0 0 40 25ZM47.62 43.225c-4.185-2.79-11.01-2.79-15.225 0-1.905 1.275-2.955 3-2.955 4.845 0 1.845 1.05 3.555 2.94 4.815C34.48 54.295 37.24 55 40 55s5.52-.705 7.62-2.115c1.89-1.275 2.94-2.985 2.94-4.845-.015-1.845-1.05-3.555-2.94-4.815Z" />
        </g>
    </svg>
)
export default UserWithBg
