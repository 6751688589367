import * as React from "react"
const RedeemIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m6.75 7.875 1.5 1.5L11.625 6M15 15.75v-9.9c0-1.26 0-1.89-.245-2.371a2.25 2.25 0 0 0-.984-.984c-.48-.245-1.11-.245-2.371-.245H6.6c-1.26 0-1.89 0-2.371.245a2.25 2.25 0 0 0-.984.984C3 3.959 3 4.589 3 5.85v9.9l2.063-1.5 1.875 1.5L9 14.25l2.063 1.5 1.874-1.5L15 15.75Z"
        />
    </svg>
)
export default RedeemIcon
