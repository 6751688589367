import * as React from "react"
const TriplePeopleRight = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={99}
    height={114}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#fff"
        d="M68.366 72.386h-19.32c-.915 0-1.697-.59-1.959-1.37-1.892-5.614-6.07-6.984-16.187-10.965-.586-.264-.652-1.043-.065-1.37 2.938-1.444 2.415-2.675 2.415-11.292a2.019 2.019 0 0 1-.326-.465c-.654-.98-1.11-2.147-1.24-3.328l-.653-.327c-1.958.465-1.828-1.633-2.35-5.677-.195-1.696.065-2.023.783-2.286l.654-.842c-3.852-8.617-1.893-14.03 1.893-15.99-1.242-2.751-1.307-3.718-1.307-3.718s8.03 1.369 10.77.778c3.525-.716 8.877.126 10.9 4.761 3.33 1.306 4.634 3.467 4.96 5.678.458 3.655-1.566 7.637-1.958 9.207.066.063.066.126.131.2.523.252.719.717.523 2.149-.458 3.856-.392 6.268-2.35 5.677l-1.763 3.065c-.065.402-.13.528-.13.729-.066.578-.066 1.696-.066 8.09 0 .778.196 1.494.588 2.084.392.716 1.045 1.244 1.828 1.57h.065c4.569 1.897 7.833 3.128 10.247 4.108 5.353 2.16 6.267 2.738 6.724 6.33.13 1.772-1.11 3.204-2.807 3.204Z"
      />
    </g>
    <path
      fill="#9AA2B2"
      d="M55.244 35.117c.196-.98-.262-2.16-.652-3.002-.066-.139-.13-.264-.196-.465-1.502-3.065-4.962-4.371-8.225-4.497-8.354-.465-9.138 1.168-11.748-1.243.782 1.243.913 3.128-.392 5.552-.848 1.695-2.545 2.411-4.047 2.939-3.786-8.617-1.893-14.043 1.892-16.003-1.24-2.738-1.305-3.718-1.305-3.718s8.03 1.37 10.77.791c3.525-.728 8.877.126 10.9 4.761 3.33 1.306 4.634 3.454 4.961 5.678.523 3.655-1.566 7.637-1.958 9.207Z"
    />
    <defs>
      <linearGradient
        id="b"
        x1={27.906}
        x2={57.29}
        y1={24.903}
        y2={24.903}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B1B7C0" />
        <stop offset={1} stopColor="#8B909A" />
      </linearGradient>
      <filter
        id="a"
        width={98.426}
        height={112.898}
        x={0.391}
        y={0.939}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={13.817} />
        <feGaussianBlur stdDeviation={13.817} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_799_1249"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_799_1249"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default TriplePeopleRight
