import * as React from "react"
const XIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m15.583 6.417-9.166 9.166m0-9.166 9.166 9.166"
        />
    </svg>
)
export default XIcon
