const CalendarIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.4}
            d="M19.25 9.167H2.75m11.917-7.334V5.5M7.333 1.833V5.5M7.15 20.167h7.7c1.54 0 2.31 0 2.899-.3a2.75 2.75 0 0 0 1.201-1.202c.3-.588.3-1.358.3-2.898v-7.7c0-1.54 0-2.31-.3-2.899a2.75 2.75 0 0 0-1.201-1.202c-.589-.3-1.359-.3-2.899-.3h-7.7c-1.54 0-2.31 0-2.898.3A2.75 2.75 0 0 0 3.05 5.168c-.3.588-.3 1.359-.3 2.899v7.7c0 1.54 0 2.31.3 2.898a2.75 2.75 0 0 0 1.202 1.202c.588.3 1.358.3 2.898.3Z"
        />
    </svg>
)
export default CalendarIcon