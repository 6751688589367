import * as React from "react"
const EmailIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.4}
            d="m1.833 6.417 7.485 5.24c.606.423.91.636 1.239.718.29.072.595.072.887 0 .33-.082.632-.295 1.238-.719l7.485-5.239M6.233 18.334h9.534c1.54 0 2.31 0 2.898-.3a2.75 2.75 0 0 0 1.202-1.202c.3-.588.3-1.358.3-2.898V8.067c0-1.54 0-2.31-.3-2.898a2.75 2.75 0 0 0-1.202-1.202c-.588-.3-1.358-.3-2.898-.3H6.233c-1.54 0-2.31 0-2.898.3a2.75 2.75 0 0 0-1.202 1.202c-.3.588-.3 1.358-.3 2.898v5.867c0 1.54 0 2.31.3 2.898a2.75 2.75 0 0 0 1.202 1.202c.588.3 1.358.3 2.899.3Z"
        />
    </svg>
)
export default EmailIcon
