const CircleIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
        {...props}
    >
        <circle cx={11} cy={11} r={7.042} stroke="#111928" strokeWidth={1.5} />
    </svg>
)
export default CircleIcon