const BlobSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={532}
    height={416}
    fill="none"
  >
    <path
      fill="#DCD7FE"
      fillRule="evenodd"
      d="M182.034 461.691C74.99 428.768 1.323 329.846.012 217.408c-1.17-100.405 82.182-174.167 176.765-206.68 83.293-28.634 169.55 2.188 229.366 67.068 78.77 85.44 165.2 196.849 106.559 297.301-63.699 109.115-210.254 123.63-330.668 86.594Z"
      clipRule="evenodd"
    />
  </svg>
)
export default BlobSvg
