import * as React from "react"
const TriplePeopleLeft = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={94}
    height={110}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#fff"
        d="M59.5 53.828c-3.133-.063-3.46-.653-3.46-.653v-5.226c3.33-2.738 6.136-5.941 6.92-10.112 2.088-.452 3.393-2.348 3.197-4.433-.065-.917-.651-1.772-.717-2.752v-1.369c0-.326 0-.59-.065-.916 0-.327-.066-.654-.066-.968-.195-1.57-.587-3.203-1.631-5.1-2.611-4.898-7.768-8.151-13.577-8.151-1.175 0-2.35.125-3.46.389-2.088.452-3.981 1.369-5.678 2.675-.326.264-.652.515-.979.78-.065.062-.13.137-.261.2-1.828 1.696-3.264 3.844-4.047 6.268-.783 2.412-.979 4.886-.652 7.373v.389c.065.453.196.39-.13.78-.066.138-.197.263-.262.464-.392.716-.719 1.696-.261 2.864.783 2.223 2.35 1.897 3.785 3.14a.504.504 0 0 1-.26.126h-.066l-1.632.528c-9.79 3.064-10.9 13.44-.849 15.337-.717.062-1.37.452-1.826.778-3.2 2.488-5.223 6.268-5.745 9.986-.195 1.118.718 2.16 1.894 2.16h15.534c.326 0 .653-.263.783-.59 1.436-5.225 4.7-7.046 11.488-9.785l3.916-1.57c.652-.264 1.37-.527 2.154-.854.718-.251.652-1.306-.13-1.494-.784-.201-1.567-.264-2.35-.327-.654.063-1.175.063-1.567.063ZM39.592 47.17c0-.653.457-1.369 1.436-2.148.392.516.914 1.106 1.5 1.62.458.403.915.792 1.438 1.182.325.2.652.452.978.715.066.063.131.139.196.139.066.062.196.125.262.188v3.203c-.066-.063-.131-.138-.196-.138-.327-.251-.588-.515-.85-.716-.455-.327-.913-.59-1.37-.842-.196-.138-.326-.2-.457-.326-1.501-.98-2.872-1.897-2.937-2.877Z"
      />
    </g>
    <path
      fill="url(#b)"
      d="M59.498 53.827c-.522 1.897-1.109 3.065-1.109 3.065l-16.84-2.675.978-4.17c.131.125.327.188.458.25.457.264.98.591 1.37.918.261.2.588.39.85.716.064.075.13.138.13.138v-3.204c-.131-.062-.196-.125-.262-.188 2.741 1.37 6.136 2.085 11.032-.791-.066 0-.066.063-.131.063v5.225s.392.59 3.525.653Z"
    />
    <path
      fill="url(#c)"
      d="M65.309 28.367a21.136 21.136 0 0 1-8.812 1.117c-4.7-.389-9.268-2.286-12.793-5.426-1.175 3.404-4.112 5.942-7.441 7.449-.457.188-.913.39-1.37.515v-.126c-.327-2.474-.131-4.961.651-7.373.784-2.412 2.22-4.572 4.048-6.268.065-.063.13-.126.26-.201.327-.251.654-.515.98-.779A16.853 16.853 0 0 1 46.51 14.6a15.465 15.465 0 0 1 3.46-.39c5.809 0 10.965 3.266 13.577 8.152.978 1.897 1.435 3.53 1.631 5.038.13.314.13.64.13.967Z"
    />
    <defs>
      <linearGradient
        id="b"
        x1={50.529}
        x2={50.529}
        y1={56.955}
        y2={51.993}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#E2E5EC" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={34.727}
        x2={65.314}
        y1={23.12}
        y2={23.12}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B1B7C0" />
        <stop offset={1} stopColor="#8B909A" />
      </linearGradient>
      <filter
        id="a"
        width={93.693}
        height={109.507}
        x={0.118}
        y={0.33}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={13.817} />
        <feGaussianBlur stdDeviation={13.817} />
        <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_799_1249"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_799_1249"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default TriplePeopleLeft