const TicketIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        {...props}
    >
        <path
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.35}
            d="M8.333 6.667v-.834m0 4.584v-.834m0 4.584v-.834m-4-10h11.334c.933 0 1.4 0 1.756.182.314.16.569.415.729.728.181.357.181.824.181 1.757v1.083a2.917 2.917 0 1 0 0 5.834V14c0 .933 0 1.4-.181 1.757-.16.313-.415.568-.729.728-.356.182-.823.182-1.756.182H4.332c-.933 0-1.4 0-1.756-.182a1.667 1.667 0 0 1-.729-.728c-.181-.357-.181-.824-.181-1.757v-1.083a2.917 2.917 0 0 0 0-5.834V6c0-.933 0-1.4.181-1.757.16-.313.415-.568.729-.728.356-.182.823-.182 1.756-.182Z"
        />
    </svg>
)
export default TicketIcon
