import * as React from "react"
const SettingsIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        {...props}
    >
        <g
            stroke="#111928"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            clipPath="url(#a)"
        >
            <path d="M9 11.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z" />
            <path d="M14.046 11.046a1.125 1.125 0 0 0 .225 1.24l.04.041a1.364 1.364 0 1 1-1.93 1.93l-.04-.041a1.125 1.125 0 0 0-1.241-.225 1.125 1.125 0 0 0-.682 1.03v.115a1.364 1.364 0 0 1-2.727 0v-.061a1.125 1.125 0 0 0-.736-1.03 1.125 1.125 0 0 0-1.241.226l-.041.04a1.365 1.365 0 1 1-1.93-1.93l.041-.04A1.125 1.125 0 0 0 4.01 11.1a1.125 1.125 0 0 0-1.03-.682h-.115a1.364 1.364 0 0 1 0-2.727h.061a1.125 1.125 0 0 0 1.03-.736 1.125 1.125 0 0 0-.225-1.241l-.041-.041a1.364 1.364 0 1 1 1.93-1.93l.04.041A1.125 1.125 0 0 0 6.9 4.01h.055a1.125 1.125 0 0 0 .681-1.03v-.115a1.364 1.364 0 0 1 2.728 0v.061a1.125 1.125 0 0 0 .681 1.03 1.125 1.125 0 0 0 1.241-.225l.041-.041a1.363 1.363 0 0 1 2.226.442 1.364 1.364 0 0 1-.296 1.487l-.041.041A1.125 1.125 0 0 0 13.99 6.9v.055a1.124 1.124 0 0 0 1.03.681h.115a1.364 1.364 0 0 1 0 2.728h-.061a1.125 1.125 0 0 0-1.03.681Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h18v18H0z" />
            </clipPath>
        </defs>
    </svg>
)
export default SettingsIcon
